<template>
    <div class="">
        <div class="bgbgbg"></div>
        <div class="bg_model"></div>
        <div class="login">
            <div class="logo">
                <img src="../../../public/register.png" alt=""/>
            </div>
            <div class="tab_con">
                <div class="tab_con_word">
                    部门<span>*</span>
                </div>
                <div class="tab_con_input">
                    <img src="../../../public/icon-class@2x.png" alt=""/>
                    <select name="department_id" v-model="department_id">
                        <option v-for="item in department" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>

                <div class="tab_con_word">
                    姓名<span>*</span>
                </div>
                <div class="tab_con_input">
                    <img src="../../../public/icon-account.png" alt=""/>
                    <input type="text" name="realname" v-model="realname" placeholder="请输入姓名"/>
                </div>

                <div class="tab_con_word">
                    手机号<span>*</span>
                </div>
                <div class="tab_con_input">
                    <img src="../../../public/icon-iphone@2x.png" alt=""/>
                    <input type="text" name="phone" v-model="phone" placeholder="请输入手机号"/>
                </div>

                <div class="tab_con_word">
                    密码<span>*</span>
                </div>
                <div class="tab_con_input">
                    <img src="../../../public/icon-iphone@2x.png" alt=""/>
                    <input type="password" name="password" v-model="password" placeholder="请输入密码"/>
                </div>

                <div class="tab_con_word">
                    验证码<span>*</span>
                </div>
                <div class="tab_con_input">
                    <img src="../../../public/icon-code@2x.png" alt=""/>
                    <input type="text" name="verify_code" v-model="verify_code" placeholder="请输入验证码"/>
                    <span v-if="isSend" @click="sendCode">{{ sendCodeStr }}</span>
                    <span v-else>{{ sendCodeStr }}</span>
                </div>

                <div class="contr">
                    <div class="item">
<!--                        <input type="checkbox" name="" id=""/>-->
<!--                        <p>您已同意<span>《服务协议》</span></p>-->
                    </div>
                    <div class="item" @click="login">
                        <p>去登录</p>
                        <img src="../../../public/icon-right.png" alt=""/>
                    </div>
                </div>
            </div>
            <div class="button">
                <p @click="register">注册</p>
            </div>
        </div>
    </div>
</template>
<script>
    import {hexMD5} from "@/utils/md5";

    export default {
        name: "login",
        data() {
            return {
                department: [],
                department_id: 0,
                realname: '',
                phone: '',
                password: '',
                verify_code: '',
                sendCodeStr: '获取验证码',
                isSend: true,
            };
        },
        created() {
        },
        mounted() {
            this.getDepartment();
        },

        methods: {
            getDepartment() {
                var that = this;
                this.$http.get('/common/department/all').then(res => {
                    that.department = res.data.data;
                });
            },

            sendCode() {
                if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))) {
                    this.$message({
                        message: "请输入正确手机号",
                        type: "warning",
                    });
                    return false;
                }
                if (this.isSend) {
                    const loading = this.$loading({
                        lock: true,
                        text: '',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.isSend = false;
                    this.$http.post('/common/sendSms', {phone: this.phone, sign: hexMD5(this.phone)}).then(res => {
                        loading.close();
                        if (res.data.code == 0) {
                            this.$message({
                                message: "发送成功",
                                type: "success",
                            });
                            this.countDown();
                        }else{
                            this.isSend = true;
                            this.$message({type: 'error', message: res.data.msg})
                        }
                    });
                } else {
                    return false;
                }
            },
            countDown() {
                var timeo = 60;
                var that = this;
                timeStop = setInterval(function () {
                    timeo--;
                    if (timeo > 0) {
                        that.isSend = false;
                        that.sendCodeStr = '重新发送' + timeo + 's';
                    } else {
                        timeo = 60;
                        that.isSend = true;
                        that.sendCodeStr = '获取验证码';
                        clearInterval(timeStop);
                    }
                }, 1000)
            },

            register(){
                var that = this;
                if (this.department_id <= 0) {
                    this.$message.warning('请选择部门');
                    return false;
                }
                if (this.realname.length == 0) {
                    this.$message.warning('请输入姓名');
                    return false;
                }
                if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))) {
                    this.$message.warning('请输入正确手机号');
                    return false;
                }
                if (this.password.length == 0) {
                    this.$message.warning('请输入密码');
                    return false;
                }
                if (this.verify_code.length == 0) {
                    this.$message.warning('请输入验证码');
                    return false;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$http.post('/doRegister', {
                    department_id: this.department_id,
                    realname: this.realname,
                    phone: this.phone,
                    password: this.password,
                    verify_code: this.verify_code,
                    source: 2,
                }).then(res => {
                    loading.close();
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg);
                        setTimeout(function () {
                            that.$router.push('/login')
                        }, 1500);
                    }else{
                        this.$message({type: 'error', message: res.data.msg})
                    }
                });
            },

            login() {
                this.$router.push('/login')
            }
        },
    };
</script>
<style lang='less' scoped>
.login {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    width: 560px;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 16px 16px 16px 16px;
    padding: 45px;
    box-sizing: border-box;

    .logo {
        margin-bottom: 28px;

        img {
            display: block;
            height: 60px;
            margin: 0 auto;
        }
    }

    .tab_title {
        margin-top: 28px;
        display: flex;
        align-items: center;

        .item {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 18px;
            color: #8D8D8D;
            line-height: 28px;
            text-align: center;
            flex: 1;
        }

        .item.active {
            color: #231815;
            position: relative;
        }

        .item.active:after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            bottom: -10px;
            transform: translate(-50%, 0);
            width: 76px;
            height: 5px;
            background: #EB9114;
        }
    }

    .tab_con {
        .tab_con_word {
            display: flex;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #303133;
            line-height: 24px;
            margin-bottom: 14px;

            span {
                color: #D91C17;
            }
        }

        .tab_con_input {
            display: flex;
            align-items: center;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #CDD0D6;
            padding: 12px 10px;
            margin-bottom: 32px;
            position: relative;

            img {
                display: block;
                width: 24px;
                margin-right: 16px;
            }

            input {
                flex: 1;
                color: #333;
                font-size: 14px;
                background: none;
            }

            select {
                flex: 1;
                color: #333;
                font-size: 14px;
                background: none;
                border: 0;
            }

            span {
                position: absolute;
                right: 10px;
                top: 10px;
                z-index: 2;
                background: #EB9114;
                border-radius: 4px 4px 4px 4px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #F8F8F8;
                padding: 2px 7px;
                cursor: pointer;
            }
        }

        .contr {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;

            .item {
                cursor: pointer;
                display: flex;
                align-items: center;

                p {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #847767;
                    line-height: 22px;
                    margin-right: 6px;

                    span {
                        color: #EB9114;
                    }
                }

                img {
                    display: block;
                    width: 16px;
                }

                input[type="checkbox"] {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    /* 用于控制 UI 控件的基于操作系统主题的原生外观。none 隐藏部件的某些特性 */
                    appearance: none;
                    margin-right: 10px;
                }

                input[type="checkbox"]::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 1px solid #d9d9d9;
                    background-color: #fff;
                    border-radius: 50%;
                }

                input[type="checkbox"]:checked::before {
                    content: "\2713";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 1px solid #f54500;
                    background-color: #f54500;
                    /* 对勾样式 */
                    color: #fff;
                    font-size: 14px;
                    text-align: center;
                    line-height: 20px;
                }

            }
        }
    }

    .button {
        margin-top: 50px;

        p {
            width: 100%;
            height: 66px;
            background: linear-gradient(16deg, #F08300 0%, #F08300 21%, #FFF100 100%);
            border-radius: 66px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 24px;
            color: #FFFFFF;
            line-height: 66px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>
